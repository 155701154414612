.tab {
    width: 100%;
}

.tabHeaderItems {
    display: flex;
    justify-content: space-around;
    height: 8vw;
    align-items: center;
}

.tabHeaderItem {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    border-right: 1px solid #aaa;
    user-select: none;
    cursor: pointer;
    height: 100%;
    transition: all .25s ease-in-out;
}

.last-thi {
    border-right: none
}


.tabUnderline {
    position: relative;
    height: 0.5vw;
    background: var(--bar);
    transition: left .2s ease-in-out;
}

.selectedtab {
    background-color: var(--bar);
    color: white;
}

.tabHeader {
    background-color: white;
    background: #6495ed2b;
    border-radius: 1.5vw;
}

@media only screen and (min-width: 1080px) {

    .tabHeaderItems {
        height: 2.7vw;
    }

    .tabHeaderItem {
        cursor: pointer;
    }

    .tabUnderline{
        height: 0.2vw;
    }

    .tabHeader {
        border-radius: 0.5vw;
    }
}