.bp-plus {
    width: .25in;
}

.bp-plus-lt {
    border-left: .01in solid black;
    border-top: .01in solid black;
}

.bp-plus-rt {
    border-right: .01in solid black;
    border-top: .01in solid black;
}

.bp-plus-lb {
    border-left: .01in solid black;
    border-bottom: .01in solid black;
}

.bp-plus-rb {   
    border-right: .01in solid black;
    border-bottom: .01in solid black;
}

.bp-plus-holder {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.bp-plus-row-holder {
    width: 100%;
    height: .25in;
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 0;
}


.bp-badge, .bp-plus-holder {
    position: relative;
    height: 5in;
    width: 3.75in;
}

.bp-plus-bottom {
    top: 2.2in;
}

.bp-details {
    font-size: .235in;
    position: relative;
    font-weight: 900;
    text-transform: uppercase;
    top: 1.9in;
    height: 2.3in;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    margin: 0 .01in 0 .1in;
}

.bp-detail {
    display: flex;
    width: 95%;
}

.bp-detail-label {
    width: 23%;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    color: #393185;
    font-weight: bolder;
}

.bp-detail-text {
    width: 72%;

    display: flex;
    flex-wrap: wrap;

    border-bottom: .019in solid #393185b7;
    padding-left: .1in;
}

.bp-lt {
    position: absolute;
    left: 0in;
    top: 0in;
    width: .1in;
    height: .1in;
    border-left: .01in solid rgba(0, 0, 0, 0.75);
    border-top: .01in solid rgba(0, 0, 0, 0.75);
}

.bp-lb {
    position: absolute;
    left: 0in;
    bottom: 0in;
    width: .1in;
    height: .1in;
    border-left: .01in solid rgba(0, 0, 0, 0.75);
    border-bottom: .01in solid rgba(0, 0, 0, 0.75);
}

.bp-rt {
    position: absolute;
    right: 0in;
    top: 0in;
    width: .1in;
    height: .1in;
    border-right: .01in solid rgba(0, 0, 0, 0.75);
    border-top: .01in solid rgba(0, 0, 0, 0.75);
}

.bp-rb {
    position: absolute;
    right: 0in;
    bottom: 0in;
    width: .1in;
    height: .1in;
    border-right: .01in solid rgba(0, 0, 0, 0.75);
    border-bottom: .01in solid rgba(0, 0, 0, 0.75);
}

.bp-qr {
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    top: 1.53in;
}
