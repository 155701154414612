.mainMenu {
    position: fixed;
    z-index: 100;
    left: 0;
    width: 70%;
    height: 100vh;
    background-color: var(--secondaryBg);
    transition: left .3s ease-in-out, opacity .3s ease-in-out;
    box-shadow: #0005 2px 2px 10px;
}

.mainMenuItem {
    color: white;
    padding: 3vw;
}

.mainMenu#show {
    left: 0px
}

.mainMenu#hide {
    left: -80vw;
}

.mainMenuProfile {
    color: white;
    display: grid;
    justify-items: center;
}

.mainMenuProfileAvatar {
    display: grid;
    background: cadetblue;
    font-size: 8vw;
    font-weight: 400;
    border-radius: 50%;
    width: 10vw;
    height: 10vw;
    align-content: center;
    justify-content: center;
    margin: 3vw;
}

.mainMenuProfileName {
    font-size: 5vw;
    font-weight: 400;
}


