.eachVolDet {
    display: flex;
    justify-content: space-between;
    margin: 2vw 0 2vw 0vw;
    flex-direction: column;
}

.servol_category {
    font-size: 3.2vw;
    color: #999;
    margin: 1vw 0 0 0;
}

.name-greyed {
    color: grey;    
}

.servol_phone {
    font-size: 3.2vw;
    color: #777;
    margin-top: .5vw;
}

.nameHolder {
    display: flex;
    align-items: center;
}

.nameCheck {
    font-size: 6vw;
}

.redlight {
    color: indianred;
    margin-bottom: 2vw;
    font-size: 3.2vw;
}

.nameact {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.phonecat {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.avcomment {
    color: indianred;
    font-size: 2.75vw;
    margin-top: 1.8vw;
    background: #eeeeeec4;
    padding: 1vw;
    border-radius: 1vw;
    width: fit-content;

}

.volactbuttons {
    display: flex;
    align-items: center;
}

.availRed {
    color: indianred;
    font-size: 3vw;
    margin-bottom: 0.5vw;
}

.ser-vol {
    border-top-left-radius: 0%;
    border-top-right-radius: 0%;
}

.name-phone {
    display: flex;
    flex-direction: column;
}

@media only screen and (min-width: 1080px) {

    .phonecat {
        flex-direction: row;
        align-items: center;
    }

    .nameCheck {
        font-size: 2.5vw;
    }

    .servol_category {
        font-size: 1vw;
        margin-top: .75vw;
    }
    .eachVolDet {
        margin: .5vw 0 .5vw 0;
    }

    .redlight {
        margin-bottom: .5vw;
        font-size: 1.1vw;
    }

    .availRed {
        color: indianred;
        font-size: 1.1vw;
        margin-bottom: 0.2vw;
    }

    .avcomment {
        color: indianred;
        font-size: 1vw;
        margin-bottom: .3vw;
        margin-top: .5vw;
        background: #eeeeeec4;
        padding: .5vw;
        border-radius: .5vw;
        width: fit-content;
    }

    .servol_phone {
        font-size: 1.0vw;
        color: #777;
        margin-top: .1vw;
    }
}