@import url('https://fonts.googleapis.com/css2?family=Domine:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@900&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e7ebf0;
}

* {
  font-family: 'Domine', serif;
}

div {
  color: black;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}