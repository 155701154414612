.slmain {
    background-color: white;
    font-size: 1.2vw;
    width: 95vw;
}

.sltable {
    border-collapse: collapse;
}

.slcell {
    border: .01vw solid black;
    padding: .1vw;
    text-align: left;
}

.page-num {
    text-align: center;
    margin-top: 1.1vw;
    font-size: 1vw;
    color: #292929;
}

.slcellhead {
    border: .01vw solid black;
    padding: .5vw;
    text-align: center;
}

.sltitle {
    text-align: center;
    padding: .5vw;
    font-size: 1.25vw;
    font-weight: 900;
    border: 0.1vw solid black;
    background-color: aliceblue;
    font-family: 'Poppins', sans-serif;
}

.slheader {
    background-color: aliceblue;
    font-family: 'Poppins', sans-serif;
}

.slhead-1 {
    width: 14vw;
}

.slhead-2 {
    width: 7.9vw;
}

.slhead-3 {
    width: 30vw;
}

.slhead-4 {
    width: 11vw;
    font-size: .9vw;
}

.slhead-5 {
    width: 15vw;
}

.slhead-6 {
    width: 13vw;
}

.slcell-1 {
    padding-left: 0.5vw;
}

.slcell-2, .slcell-4, .slcell-5, .slcell-6 {
    text-align: center;
}

.page-break {
    page-break-before: always; /* Start on a new page */
}