.ser {
    display: flex;
    justify-content: center;
    margin: 4vw 0 0 0;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
}

.spoc-title {
    margin-bottom: 3vw;
    font-weight: 900;
    text-align: center;
}

.ser-pass {
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    outline: none;
    text-align: center;
    font-size: 8vw;
    width: 50vw;

    font-family: 'Courier New', Courier, monospace;
}

.ser-lock-bg {
    background-color: rgba(255, 255, 255, 0.9);
    height: 100vh;
    width: 100vw;

    position: absolute;
    top: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 999;
}

.spocresplink {
    width: 100%;
    text-decoration: underline;
    color: var(--bar);
    margin: 0 9vw 4vw 0;
    text-align: center;
}

.serServ{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 73vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.svHolder {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 4vw;
}

.filterBody {
    width: 100vw;
    display: flex;
    justify-content: space-around;
    margin-top: 3vw;
}

.filterSel {
    width: 90vw;
    height: 8vw;
}

.fillabel {
    color: #555;
    margin-bottom: 0.1vw;
    font-size: 3.75vw;
}

.pageMainDiv {
    width: 100vw;
}

.filselect {
    width: 40vw;
    margin-top: .5vw;
    font-size: 4vw;
    padding: 1vw;
}

.ser-count {
    width: 100%;
    text-align: end;
    margin-right: 15vw;
    color: #666;
}

.sv-count {
    color: #555;
    font-size: 4vw;
}

.sv-coup {
    background-color: var(--bar);
    color: white;
    padding: 2vw;
    font-size: 3.5vw;
    margin-right: .5vw;
}

.sv-info-header {
    display: flex;
    width: 85vw;
    justify-content: space-between;
    font-size: 3.5vw;
    align-items: center;
}

.ser-count-sv {
    width: 100%;
    text-align: start;
    margin-left: 15vw;
    color: #666;
}

.noservices {
    text-align: center;
    margin-top: 10vw;
}

.downlaodicon {
    color: var(--bar);
    font-size: 5vw;
    display: flex;
}

.downlaodicon:hover {
    color: blue;
}

.ser-event-name {
    width: 100%;
    text-align: left;
    color: #555;
    font-size: 3.5vw;
    margin: 2vw 0 2vw 14.5vw;
}

@media only screen and (min-width: 1080px) {

    /* Desktop */

    .svHolder {
        margin-bottom: 1.5vw;
    }

    .ser-pass {
        font-size: 2vw;
        width: 15vw;
    }
    
    .ser-event-name {
        font-size: 1.25vw;
        margin: 0.75vw 0 1vw 16.5vw;
    }

    .sv-coup {
        background-color: var(--bar);
        color: white;
        padding: 0.5vw;
        font-size: 1.1vw;
        margin-right: 1.5vw;
    }

    .sv-count {
        font-size: 1.5vw;
        margin-left: 1vw;
    }

    .spoc-title {
        margin-bottom: 1.5vw;
        font-weight: 900;
        text-align: center;
    }

    .spocresplink {
        width: 100%;
        text-decoration: underline;
        color: var(--bar);
        margin: 0 0 2vw 0;
        text-align: center;
        cursor: pointer;
    }

    .fillabel {
        font-size: 1.2vw;
        margin-left: 1vw;
    }

    .filselect {
        font-size: 1.2vw;
        padding: 0.4vw;
        margin-top: 0;
        margin-left: 1.5vw;
        margin-right: 1.5vw;
        max-width: 20vw;
    }

    .filterBy, .filtervalue{
        display: flex;
        align-items: center;
    }

    .filterBody {
        margin-top: 1vw;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .ser {
        margin-top: 1.2vw;
    }

    .downlaodicon {
        color: var(--bar);
        font-size: 2.8vw;
        width: 1.6vw;
        cursor: pointer;
        filter: invert(34%) sepia(88%) saturate(1001%) hue-rotate(182deg) brightness(95%) contrast(93%);
    }
    .downlaodicon:hover {
        filter: invert(34%) sepia(88%) saturate(1001%) hue-rotate(182deg) brightness(45%) contrast(93%);
    }

    .serServ{
        max-height: 70vh;
    }

    .ser-count {
        margin-right: 18vw;
    }
   
  }
  