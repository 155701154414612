@page {
    size: 19in 13in;
    margin: 0;
}



.bp-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 13in;
    width: 19in;
    justify-content: center;
    background-color: white;
}

.bp-row {
    display: flex;
}
