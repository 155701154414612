.colabel-spocbld {
    max-height: 90vh;
    overflow-y: scroll;
    margin: 2vw;
    background-color: white;
    width: 90vw;
}

.colabel-countrow {
    display: flex;
    flex-direction: column;
}

.colabel-counts {
    display: flex;
}

.colabel-spocell {
    background-color: white;
}

.colabel-cocell {
    border: 1px solid black;
    padding: .5vw;
}

.colabel-cotable {
    border-collapse: collapse;
    font-size: 2.2vw;
    text-align: center;
    background-color: white;
    width: 32vw;
    margin: .75vw .45vw .75vw .45vw;
    font-weight: bolder;
}

.colabel-spocb, .spocl, .spocd {
    text-align: center;
}

.colabel-spochead {
    background-color:bisque;
}

.colabel-Label {
    display: flex;
    flex-wrap: wrap;
}

.colabel-spocdateip {
    color: black;
    width: 40vw;
    text-align: center;
    padding: 0.3vw;
    font-size: 4vw;
    margin-top: 2vw;
    margin-left: 1vw;
}

.colabel-bldroot {
    display: flex;
    align-items: center;
    width: 100vw;
    height: fit-content;
}