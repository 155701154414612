.bpr-root {
    display: flex;
    flex-direction: column;
    align-items: center;
    user-select: none;
}

.bpr-badge {
    height: 5in;
    width: 3.75in;
}

.bpr-names {
    max-height: 5in;
    overflow-y: scroll;
    background: white;
    padding: 0 1vw 0 1vw;
    user-select: all;
}

.bpr-date-input-label {
    font-size: 1vw;
    color: #555;
    margin-right: 1vw;
}

.bpr-auto {
    font-size: .9vw;
    padding: .3vw;
    width: 30vw;
}

.bpr-date-select {
    font-size: .9vw;
    padding: 0.2vw;
    margin-right: .5vw;
}

.bpr-date-input {
    display: flex;
    align-items: center;
}

.bpr-inputs {
    display: flex;
    width: 80vw;
    justify-content: center;
    align-content: baseline;
    margin: 2vw;
    flex-wrap: wrap;
}

.bpr-images {
    display: flex;
    justify-content: space-evenly;
    width: 100vw;
}

.bpr-drop {
    border: .25vw dashed #aaa;
    border-radius: 1vw;
}

.bpr-prompt-text {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #555;
}

.bpr-auto-drop {
    font-size: 1vw !important;
    padding: .1vw !important;    
}

.bpr-next-holder {
    display: flex;
    align-items: center;
}

.bpr-next {
    margin: 0vw .3vw 0vw .3vw;
}