.links, .names {
    margin: 1vw;
    background: white;
    padding: 1vw;
    border: 1px black solid;
    max-height: 30vh;
    overflow: scroll;
    font-family: monospace;
    font-size: 1vw;
    white-space: pre;
    width: 25vw;
}

.waholder {
    display: flex;
    flex-direction: column;
}

.msgcopy {
    background-color: white;
    color: black;
    font-size: 1.2vw;
    box-shadow: -0.2vw 0.2vw 0.2vw #aaa;
    margin-top: 1vw;
}

.msgcopy:hover {
    background-color: #eee;
}

.msgmain {
    display: flex;
    width: 100vw;
    justify-content: space-around;
    align-items: center;
}

.msgRoot {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.msgfunc {
    width: 25vw;
    font-size: 1vw;
    padding: .4vw;
}

.msgnamesbuttons {
    width: 25vw;
    max-height: 70vh;
    overflow-y: scroll;
}

.msgnamebutton {
    background-color: white;
    box-shadow: -.2vw .2vw .2vw #aaa;
    margin: .5vw;
    padding: .5vw;
    cursor: pointer;
}

.msgnamebutton:hover {
    background-color: #eee;
}

.msgwa {
    height: 70vh;
    width: 30vw;
    background-color: #f0ebe4;
    max-height: 70vh;
    overflow-y: scroll;
}

.msgheader {
    background-color: #f0f2f5;
    height: 3vw;
    display: flex;
    align-items: center;
    padding-left: 1vw;
}

.msgMsg {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.msgMsgTxt {
    background-color: #d9fdd3;
    width: 70%;
    margin: .9vw;
    border-radius: .2vw;
    padding: .5vw;
    font-size: 1.3vw;
    white-space: pre-wrap;
    font-family: emoji;
}

.msgMsgTxt > b {
    font-size: 1.1vw;
}

.msgOptions {
    display: flex;
    align-items: center;
    margin: 1vw;
}

.msgCount {
    margin-left: 1vw;
}