.servIndiv {
}

.serviceDetails {
    display: flex;
    color: #555;
    font-size: 3.5vw;
    margin: 1vw;
}

.spocPhoneDet {
    display: flex;
    color: #555;
}

.serviceTitleStats {
    display: flex;
    justify-content: space-between;
}

.spocPP {
    margin-right: 3vw;
    color: #555;
}

.ser-service {
    border-bottom-left-radius: 0%;
    border-bottom-right-radius: 0%;
    background-image: linear-gradient(to right, aliceblue , white);
}

@media only screen and (min-width: 1080px) {
    .serviceDetails{
        font-size: 1.3vw;
        margin: .5vw;
    }

    .serviceTitleStats {
        font-size: 1.4vw;
    }

    i {
        font-size: 1.2vw;
        margin: .3vw;
    }

    .spocPP {
        margin-right: .7vw;
    }

}