.header_logo {
    width:12vw;
}

.appHeader {
    background-color: var(--bar);
    color: white;
    font-size: 4.3vw;
    text-align: center;
    padding: 2vw;
}

.head-title {
    color: white;
    display: flex;
    justify-content: center;
}

#menuButton {
    margin: 0% 3% 0% 0%;
    font-size: 4vw;
}

#slideMenuButton {
    margin: 0% 0% 0% 3%;
}

.clickableIcon {
    display: flex;
    width: 6vw;
    height: 6vw;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    border-radius: 50%;
}

@media only screen and (min-width: 1080px) {

    .appHeader {
        font-size: 1.3vw;
        padding: 0.6vw;
    }
   
}