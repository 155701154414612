.spinner-bg-circle {
    border: 0.75vw solid rgba(128, 128, 128, 0.397);
    border-top: 0.75vw solid var(--bar);
    border-right: 0.75vw solid var(--bar);
    border-bottom: 0.75vw solid var(--bar);
    width: 15px;
    height: 15px;
    border-radius: 50%;
    animation: spinner 1s ease-in-out infinite;
    transition: opacity 0.5s;
    opacity: 1;
}

.spinner-show {
 opacity: 1;
}

.spinner-hide {
 opacity: 0;
}

@keyframes spinner {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}