.vol {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3.1vh;
}

.serpaper {
    margin-top: 1vw;
}

.vol-events-holder {
    margin-top: 2vw;
}

.guidelink {
    width: 100%;
    text-decoration: underline;
    color: var(--bar);
    margin: 2vw 0 2vw 0;
    text-align: center;
}

.autoinvol {
    width: 80vw;
    height: 3vw;
    padding: 2vw;
    font-size: 4vw;
}

.event-name {
    background: aliceblue;
    padding: 1vw;
    text-align: center;
}

.pracoup {
    background-color: #1976d21f;
    padding: 2vw;
    width: 90%;
}

.voldetholderv {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.volnameintab {
    font-size: 4.5vw;
    color: #000000d4;
    font-weight: bolder;
    margin: 2vw 0 4vw 0;
    display: flex;
    justify-content: center;
}

.volnameauto {
    font-size: 4.25vw;
}

.vol-app-cta {
    color: #555;
    margin: 2vw;
    text-align: justify;
}

.vol-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 95vh;
    overflow-y: scroll;
}

.vol-note-main {
    font-size: 3.5vw;
}

.vol-note {
    margin-top: 2vw;
    font-size: 3.5vw;
    text-align: justify;
}

.vol-note-li {
    margin-top: 2vw;
    width: 95%;
}

.vol-app-spin {
    display: flex;
    justify-content: center;
}

.vol-app-paper {
    margin-top: 5vw;
}

.vol-app-note {
    text-transform: uppercase;
    margin: 2vw;
    color: #888;
}

.vol-app-desch {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 2vw;
}

.vol-app-action {
    color: #fff;
    background-color: var(--bar);
    text-align: center;
    padding: 2vw;
    margin: 2vw;
    cursor: pointer;
}

@media only screen and (min-width: 1080px) {

    .vol-note {
        margin-top: 2vw;
        font-size: 1.5vw;
    }

    .vol-events-holder {
        margin-top: 1vw;
    }

    .volnameauto {
        font-size: 1.25vw;
    }

    .autoinvol {
        width: 80vw;
        height: 1vw;
        padding: .75vw;
        font-size: 1.2vw;
    }

    .volnameintab {
        font-size: 1.5vw;
        margin: .25vw 0 1.25vw 0;
    }

    .pracoup {
        background-color: #1976d21f;
        padding: 1vw;
        width: 95%;
    }

    .serpaper {
        margin-top: .6vw;
    }

    .vol-note-li {
        margin-top: 1vw;
    }

    .vol {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 3vh;
    }

    .vol-note {
        margin-top: 1.25vw;
    }

    .vol-note-main {
        font-size: 1.25vw;
    }

    .vol-app-paper {
        margin-top: 1.5vw;
    }

    .vol-app-note {
        margin: .2vw;
    }
    
    .vol-app-desch {
        margin: .2vw;
    }
    
    .vol-app-action {
        padding: .7vw;
        margin: .2vw;
    }

}